import apiClient from './apiMainClient'

/**
 * Verifica a quantidade de falhas de login acumuladas em um determinado email.
 *
 * Quando existir mais de 3 falhas o form de autenticação deve apresentar o Recaptcha,
 * pois o token será exigido pelo método de login da API.
 *
 * @param {Object} email - Email do usuário a ser verificado.
 * @returns {Promise<Object[]>} - { count: 0 }.
 */
 export const postLoginFailsCount = async (email) => {
  try {
    const response = await apiClient.post('/auth/login-fails-count', JSON.stringify({ email }))
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Tenta realizar a autendicação com base nas credenciais.
 *
 * @param {Object} data - Objeto data para autenticação.
 * @param {String} [data.email] - Email do usuário (único no sistema).
 * @param {String} [data.password] - Senha.
 * @param {string} [data.recaptcha] - Token gerado pelo recaptcha. Obrigatório quando mais de 3 falhas
 * @returns {Promise<Object[]>} - Token JWT.
 */
export const postLogin = async (data) => {
  try {
    const response = await apiClient.post('/auth/login', JSON.stringify(data))
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Solicitação a inicialização de processo de recuperação de senha para um determinado email.
 *
 * @param {Object} data
 * @param {String} [data.email] - Email do usuário (único no sistema).
 * @param {string} [data.recaptcha] - Token gerado pelo recaptcha. Obrigatório quando mais de 3 falhas
 */
export const postForgotPassword = async (data) => {
  try {
    const response = await apiClient.post('/auth/forgot-password', JSON.stringify(data))
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Tenta realizar a autendicação com base nas credenciais.
 *
 * @param {Object} data - Objeto data para autenticação.
 * @param {String} [data.password] - Senha.
 * @param {String} [data.token] - JWT recebido por email.
 */
export const patchChangePassword = async (data) => {
  try {
    const response = await apiClient.patch('/auth/change-password', JSON.stringify(data))
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Obtem informações do usuário logado.
 *
 * @returns {Promise<Object[]>}
 */
export const getMe = async () => {
  try {
    const response = await apiClient.get('/auth/me')
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Verifica na API se o token armazenado na interface é válido.
 *
 * @returns {Promise<Object[]>}
 */
export const getValid = async () => {
  try {
    const response = await apiClient.get('/auth/valid')
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
