import { createSlice } from '@reduxjs/toolkit'
import { postLogin, getMe, getValid } from '../services/authService'
import { fetchTelemedicine } from './telemedicineSlice'
import _ from 'lodash'

const initialState = {
  isAuthenticated: false,
  user: null, //{ userId: '', telemedicineId: '', type: 'root'}
  jwt: null,
  loading: false,
  error: null,
  sseData: {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true
      state.error = null
    },
    loginSuccess(state, action) {
      state.isAuthenticated = true
      state.user = action.payload.user
      state.jwt = action.payload.jwt
      state.loading = false
    },
    loginFailure(state, action) {
      state.loading = false
      state.error = action.payload
    },
    logout(state) {
      state.loading = false
      state.error = null
      state.isAuthenticated = initialState.isAuthenticated
      state.jwt = initialState.jwt
      state.user = initialState.user
      state.sseData = initialState.sseData
      localStorage.removeItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`)
      sessionStorage.removeItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`)
    },
    setSseData(state, action) {
      const newData = { ...state.sseData, ...action.payload }
      if (!_.isEqual(state.sseData, newData)) {
        state.sseData = newData
      }
    },
    clearSseData(state) {
      state.sseData = {}
    },
  },
})

export const { loginStart, loginSuccess, loginFailure, logout, setSseData, clearSseData  } = authSlice.actions
export default authSlice.reducer

let eventSource = null
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL // 'http://localhost:3000/api/'

export const startSse = () => (dispatch, getState) => {
  if (eventSource) {
    eventSource.close()
  }

  let retryCount = 0

  const connect = () => {
    const { isAuthenticated, jwt } = getState().auth
    if (!isAuthenticated) {
      return
    }

    const url = new URL(`${API_BASE_URL}server-sent-events`)
    url.searchParams.append('jwt', jwt)

    eventSource = new EventSource(url)

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      dispatch(setSseData(data))
      retryCount = 0 // Reset retry count on successful message
    }

    eventSource.onerror = () => {
      console.log('SSE error. Closing connection.')
      eventSource.close()
      retryCount += 1
      const retryTimeout = Math.min(1000 * 2 ** retryCount, 30000) // Exponential backoff with a max of 30 seconds
      setTimeout(() => {
        connect()
      }, retryTimeout)
    }
  }

  connect()
}

export const stopSse = () => () => {
  if (eventSource) {
    eventSource.close()
    eventSource = null
  }
}

/**
 * Realiza login com as credenciais.
 *
 * @param {Object} credentials Objeto contendo 'email' e 'password'
 * @returns
 */
export const login = (credentials, rememberMe, callback = () => {}) => async (dispatch) => {
  dispatch(loginStart())
  try {
    const response = await postLogin(credentials)
    const { token } = response

    // seta antes para para o getMe já utilizar
    if (rememberMe) {
      localStorage.setItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`, token)
    } else {
      sessionStorage.setItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`, token)
    }

    // Busca informações do usuário
    const user = await getMe()

    dispatch(loginSuccess({ user, jwt: token }))

    callback()
  } catch (error) {
    dispatch(loginFailure(error.message))
    callback(error)
  }
}

/**
 * Verifica se existe JWT armazenado no session ou local storage para restaurar estado de autenticação.
 */
 export const initializeAuthState = () => async (dispatch) => {
  dispatch(loginStart())

  try {
    // Tenta obter o JWT do localStorage ou sessionStorage
    const jwtSession = sessionStorage.getItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`) // janela atual
    const jwtLocal = localStorage.getItem(`${process.env.REACT_APP_BROWSER_STORAGE_PREFIX}JWT`) // qualquer janela

    const token = jwtSession || jwtLocal

    if (!token) {
      dispatch(logout())
      return
    }

    // verifica se token é válido
    try {
      const validateResult = await getValid()
      if (!validateResult.valid) {
        dispatch(logout())
        return
      }
    } catch (error) {
      dispatch(logout())
      return
    }

    const user = await getMe()

    dispatch(loginSuccess({ user, jwt: token }))

    dispatch(fetchTelemedicine())

  } catch (error) {
    dispatch(loginFailure(error.message))
  }
}
